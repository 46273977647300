import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import FeaturedImg from "../components/featuredImg";
import ImgWithOrient from "../components/imageOrient";
import { css } from "@emotion/core";
import SEO from "../components/SEO";

export default ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <Helmet>
        <title>{`${post.frontmatter.title}, ${post.frontmatter.caption}`}</title>
      </Helmet>

      <SEO pagePath={post.fields.slug} postNode={post} postSEO />

      <article>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <FeaturedImg
            alt={post.frontmatter.title}
            fluid={post.frontmatter.cover.childImageSharp.fluid}
          />

          <div
            css={css`
              min-height: 45vh;
              margin: 30vw 0 15vw 0;
              padding: 6.5vw 9.333vw 0 9.333vw;
              @media (min-width: 768px) {
                display: flex;
                flex-direction: column;
                width: calc(60% - 2vw);
                margin: 20vw 0;
                padding: 0 9.333vw;
                position: relative;
              }
            `}
          >
            <Header
              title={post.frontmatter.title}
              description={post.frontmatter.description}
            />
          </div>
        </div>

        <div
          css={css`
            .image {
              margin: 9.375vw 0;
            }
            @media (min-width: 768px) {
              max-width: 915px;
              margin: 12.5vw auto;
              padding: 0 8vw;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;

              .image {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 0 10px;

                &:not(:first-of-type) {
                  margin: 9.375vw 10px 0 10px;
                }
              }

              .image.portrait {
                flex-basis: calc(50% - 20px);
              }

              .image.landscape {
                flex-basis: calc(100% - 20px);
              }

              .image.square {
                flex-basis: calc(60% - 40px);
                margin-left: 25%;
              }
            }
          `}
        >
          {data.allFile.edges.map(({ node }, i) => (
            <ImgWithOrient
              key={i}
              className="image"
              aspectRatio={node.childImageSharp.fluid.aspectRatio}
              alt={node.name}
              fluid={node.childImageSharp.fluid}
              offset={i}
            />
          ))}
        </div>

        <div
          css={css`
            min-height: 45vh;
            padding: 0 9.333vw;
            clear: both;
            max-width: 600px;
          `}
        >
          <h2
            css={css`
              text-transform: uppercase;
              font-weight: 600;
            `}
          >
            {post.frontmatter.caption}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $absolutePathRegex: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        modified
        caption
        description
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: $absolutePathRegex }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
